html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust:100%;
  font-size: 28px;
  color: @font-color;
}

body {
  font-size: 28px;
  font-family: @font-default;
  background: @pageBg;
}

body,html {
  height: 100%;
}

body, div, dl, dt, p, dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,blockquote,table,tr,th,td { margin:0;padding:0; border:none;}

h1,
h2,
h3,
h4,
h5 {
  font-size: 100%;
  font-weight: normal;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
}

b,
strong {
  font-weight: bolder
}

img {
  border-style: none
}

svg:not(:root) {
  overflow: hidden
}

button,
input,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  outline: 0;
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0
}


textarea {
  overflow: auto
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

template {
  display: none
}

[hidden] {
  display: none
}

i {
  font-style: normal;
}

ul {
  list-style: none;
}

.left{
  float: left;
}
.right{
  float: right;
}
.clearfix::after{
  content: " ";
  display: block;
  clear: both;
}