.container {
	display: flex;
	width: 100%;
	min-height: 100%;
	box-sizing: border-box;
}

.main-content {
	flex: 1;
	background: #fff;

	.result-content {
		width: 100%;
		padding: 48px 0 96px;

		.result-icon {
			margin: 0 auto;
			display: block;
			width: 106px;
			height: 106px;

			.icon-processed {
				color: #1dd8a3;
				font-size: 144px;
			}
			.icon-success {
				font-size: 106px;
				color: #56d05a;
			}

			.icon-clear {
				font-size: 106px;
				color: #f15451;
			}
		}
		.result-img {
			width: 80px;
			height: 80px;
			margin: 0 auto;
			.img-box {
				width: 100%;
				height: 100%;
				background: url('@{imgUrl}phone-none-bg.png') no-repeat;
				background-size: 100% 100%;
			}
		}
		.result-desc {
			margin: 48px 0 16px;
			width: 100%;
			height: 48px;
			font-size: 32px;
			color: rgba(0, 0, 0, 0.85);
			text-align: center;
			line-height: 48px;
		}
		
		.result-tip {
			width: 100%;
			height: 34px;
			font-size: 28px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #9a9ea7;
			text-align: center;
			line-height: 34px;
			&.tip-flex {
				// display: flex;
				font-size: 28px;
				// align-content: flex-start;
				text-align: left;
				span {
					width: max-content;
					word-break: break-all;
				}
				.text {
					color: #8c8c8c;
				}
				.number {
					color: #697df4;
					padding: 0 2px;
				}
			}
		}
	}
}
