// font
@font-EN: -apple-system-font, "Helvetica Neue";
@font-CN: "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei";
@fontSans: sans-serif;
@font-default: @font-EN, @fontSans;
@line-height-base:        1.428571429; // 20/14
@imgUrl:'../images/';

@font-color: #8C8C8C;
@font-color-white: #fff;
@font-color-dark: rgba(0, 0, 0, 0.85);
@font-color-purple: #697DF4;
@font-color-green: #00D05B;
@font-color-red: #FF5050;
@font-color-yellow: #F8C232;
@font-color-disabled: @font-color;  //目前是发送过验证码后
@font-icon-color: #555;

@split-line-color: #F6F6F6;
 
@input-bg-color: #F6F6F6;
@input-font-color: #262626;


@btn-bg-color: #697DF4;
@btn-border-color: @btn-bg-color;

@pagePadding: 32px;
@pageBg: #fff;


.txtEllipsis{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}