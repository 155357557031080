.page-record{
	position: relative;
	padding: 0 @pagePadding;
	display: flex;
	flex-direction: column;
	.hd{
		position: absolute;
		width: calc(100% - (2 * @pagePadding));
		height: 150px;
		display: flex;
		justify-content: space-between;
		.select{
			margin-top: 40px;
			color: @font-color;
			font-size: 30px;
			line-height: 30px;
			margin-bottom: 20px;
		}
		.total{
			color: @font-color;
			font-size: 24px;
			line-height: 24px;
		}
	}
	.bd{
		width: 100%;
		margin-top: 150px;
		overflow: hidden auto;
		margin-right: -@pagePadding;
		padding-right: @pagePadding;
		.record-list{
			.record-item{
				display: flex;
				align-items: center;
				&:focus{
					outline: none;
				}
				.icon-calendar{
					position: relative;
					font-size: 66px;
					color: @font-color;
					margin-right: 38px;
					span{
						position: absolute;
						left: 0;
						top: 16px;
						width: 100%;						
						line-height: 50px;
						text-align: center;
						font-size: 22px;
						color: @font-color-dark;
						font-weight: 500;
					}
				}
				.cell{
					display: flex;
					justify-content: space-between;
					flex: 1;
					border-bottom: 1px solid @border-color; //ignore
					padding: 40px 0;
					h3{
						font-size: 30px;
					}
					p{
						color: @font-color;
						font-size: 24px;
					}
					.cell-bd{
						h3{
							color: @font-color;
							margin-bottom: 16px;
						}
					}
					.cell-ft{
						h3{
							color: @font-color-dark;
							margin-bottom: 19px;	
							text-align: right;						
						}
						p{
							text-align: right;
						}
					}
				}
			}
		}
		.empty{
			margin: 250px auto;
			color: @font-color;
			text-align: center;
			font-size: 24px;
			line-height: 24px;
			.iconfont{
				font-size: 74px;
				color: @font-color;

			}
			p{
				margin-top: 50px;
			}
		}
	}
	.loading-bar{
		font-size: 28px;
		line-height: 32px;
		padding: 35px 0;
		text-align: center;
		color: @font-color;

		&.hide{
			display: none;
		}
	}
}