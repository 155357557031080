.toast-box{
    display: none;
    width: max-content;
    max-width: 80%;
    background: rgba(10, 3, 20, 0.65);
    border-radius: 8px;
    font-size: 28px;
    color:rgba(255,255,255,0.95);
    line-height: 40px;
    padding:20px 32px;
    position:fixed;
    top:40%;
    left: 50%;
    transform: translateX(-50%);
    
    .iconfont{
		float: left;
        margin-right: 8px;
		font-size: 32px;
    }
}