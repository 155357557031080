@import "./common/normalize.less";
@import "./common/variables.less";

@import "./common/btn.less";
@import "./common/result.less";
@import "./common/toast-box.less";

// @import "./fonts/iconfont.css";
@import "//at.alicdn.com/t/font_3022756_n3vkvupuhxh.css";
@import "./ui/global.less";
@import "./ui/index.less";
@import "./ui/security.less";
@import "./ui/modal.less";
@import "./ui/form.less";
@import "./ui/detail.less";
@import "./ui/certification.less";
@import "./ui/record.less";
@import "./ui/result.less";
@import "./ui/cash.less";
@import "./common/input.less";
@import "./ui/account-cancellation.less";
