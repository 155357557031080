.page-password {
  background: #fff;
  flex: 1;
  .form {
    padding: 105px @pagePadding 0;
  }
}
.form {
  // padding: 105px @pagePadding 0;
  .form-title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 23px;
    color: @font-color-dark;
  }
  .form-desc {
    font-size: 28px;
    line-height: 40px;
    color: @font-color;
    + .form-desc {
      margin-top: 16px;
    }
    &.purple {
      color: @font-color-purple;
    }
    + .form-item,
    + .form-title {
      margin-top: 48px;
    }
  }

  .error-tip {
    height: 40px;
    line-height: 40px;
    color: @font-color-red;
    font-size: 24px;
    margin-bottom: 50px;
  }
}
// }
.form-item {
  width: 100%;
  height: 96px;
  background: #f6f6f6;
  border-radius: 8px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 24px;
  .get-code {
    font-size: 24px;
    &.disabled {
      color: @font-color-disabled;
    }
  }
  .form-input {
    flex: 1;
	min-width: 100px;
  }
  .form-input-icon {
    // flex:1;
    width: 36px;
    margin-right: 24px;
    font-size: 36px;
    color: #555555;
  }
  .send-code {
    padding-left: 24px;
    font-size: 28px;
    color: #697df4;
    text-align: center;
    border-left: 2px solid #979797;
    &.disabled {
      color: #979797;
      // display: none;
    }
  }

  // &:first-child {
  // 	margin-top: 135px;
  // }
}
