.result-box {
  text-align: center;
  padding: 0 @pagePadding;
  .result {
    display: inline-block;
    padding-top: 128px;
    &-img {
      width: 160px;
      height: 160px;
    }
    > .iconfont {
      font-size: 150px;
      &.icon-success {
        color: @font-color-green;
      }
    }
    &-title {
      margin-top: 32px;
      font-size: 32px;
      color: @font-color-dark;
      height: 48px;
      line-height: 48px;
    }
    &-desc {
      margin-top: 16px;
      color: @font-color;
      font-size: 28px;
      line-height: 40px;
      padding: 0 22px;
      .desc-title {
        text-align: left;
        margin-top: 48px;
        margin-bottom: 16px;
      }
      > .desc-txt {
        text-align: left;
        + .desc-txt {
          margin-top: 8px;
        }
      }
      + .result-info {
        margin-top: 38px;
      }
    }
    &-info {
      text-align: left;
      margin-top: 26px;
      font-size: 28px;
      color: @font-color-dark;
      height: 40px;
      line-height: 40px;
    }
    + .btn {
      margin-top: 80px;
	  margin-bottom: 32px;
    }
  }
}
