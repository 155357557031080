.page-security {
  .header {
    height: 480px;
    overflow: hidden;
    border-bottom: 16px solid @split-line-color;
    .security-title {
      margin-top: 60px;
      padding-top: 290px;
      height: 72px;
      font-size: 40px;
      font-weight: 500;
      color: @font-color-dark;
      line-height: 72px;
      text-align: center;
      background: url("@{imgUrl}bg-security.png") no-repeat top center;
      background-size: 280px 280px;
    }
  }
  // 列表见index.less

  // 安全提醒
  .safety-tips {
    margin-top: 40px;

    .warnning {
      display: flex;
      line-height: 40px;
      padding-bottom: 24px;
      border-bottom: 1px solid @split-line-color;
      .iconfont {
        color: #979797;
        font-size: 32px;
      }
      &-txt {
        margin-left: 8px;
      }
    }
    .tips-item {
      display: flex;
      height: 144px;
      justify-content: space-between;
      align-items: center;
      .tip-title {
        font-size: 30px;
        height: 44px;
        line-height: 44px;
        color: @font-color-dark;
      }
      .tip-desc {
        margin-top: 8px;
        height: 36px;
        line-height: 36px;
        font-size: 24px;
        color: @font-color;
      }
      .tip-switch {
        width: 96px;
        height: 56px;
        line-height: 56px;
        border-radius: 28px;
        background: #dedede;
        position: relative;
        display: flex;
        align-items: center;
        padding: 3px;
        box-sizing: border-box;
        transition: all 0.2s linear;
        .switch-ico {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #fff;
          transform: translateX(0);
          transition: transform 0.2s linear;
        }
        &.checked {
          background: #697df4;
          .switch-ico {
            transform: translateX(40px);
          }
        }
      }
    }
  }

  // 密保问题
  .safety-questions {
    line-height: 40px;
    .safety-title {
      padding-top: 48px;
      line-height: 48px;
      font-size: 32px;
      color: @font-color-dark;
      font-weight: 500;
    }
    .safety-desc {
      margin-top: 20px;
    }
    .question {
      margin-top: 42px;
      &-title {
        padding-left: 60px;
      }
      .title1 {
        background: url("@{imgUrl}question-1.png") no-repeat;
        background-size: 44px 36px;
      }
      .title2 {
        background: url("@{imgUrl}question-2.png") no-repeat;
        background-size: 44px 36px;
      }
      .title3 {
        background: url("@{imgUrl}question-3.png") no-repeat;
        background-size: 44px 36px;
      }
    }
    .select-box {
      position: relative;
      width: 100%;
      height: 96px;
      line-height: 96px;
      background: @input-bg-color;
      color: @input-font-color;
      border-radius: 8px;
      margin-top: 18px;
      &.open {
        .select-label .icon-arrow-down {
          transform: rotateX(180deg);
        }
      }
      .select-label {
        display: flex;
        justify-content: space-between;
        padding: 0 32px;
        .iconfont {
          transition: all 0.2s linear;
          color: #ced0d1;
          font-size: 24px;
        }
      }
      .option-list {
        display: none;
        overflow: hidden;
        position: absolute;
        z-index: 2;
        top: 108px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        box-sizing: border-box;
        padding: 0 32px 0;
        .select-option {
          &:first-child {
            margin-top: 32px;
          }
          height: 40px;
          line-height: 40px;
          margin-bottom: 32px;
          &:hover {
            color: @font-color-purple;
          }
        }
      }
    }
    .form-item {
      box-sizing: border-box;
      padding: 0 32px;
      margin-top: 16px;
    }
    .btn-primary {
      margin-top: 80px;
    }
  }

  //   登录保护
  //   .login-protect{
  // 	 .protect-title{
  // 		 padding-top: 322px;
  // 		 line-height: 48px;
  // 		 color: @font-color-dark;
  // 		 font-size: 32px;
  // 		 background: url("@{imgUrl}protected.png") no-repeat center 128px;
  // 		 background-size: 160px 160px;
  // 		 text-align: center;
  // 		 &.unprotected{
  // 			background: url("@{imgUrl}unprotected.png") no-repeat center 128px;
  // 			background-size: 160px 160px;
  // 		 }
  // 	 }
  // 	 .protect-desc{
  // 		 margin-top: 16px;
  // 		 line-height: 40px;
  // 		 padding: 0 22px 80px;
  // 		 p + p{
  // 			 margin-top: 8px;
  // 		 }
  // 	 }
  //   }
}
