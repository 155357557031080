.btn{
	display: block;
	font-size: 32px;
	width: 100%;
	height: 96px;
	line-height: 96px;
	border-radius: 8px;
	text-align: center;
	&.btn-bordered{
		box-sizing: border-box;
		border: 1px solid @btn-border-color;  //ignore
		color: @btn-border-color;
	}
	&.btn-primary{
		color: @font-color-white;
		background: @btn-bg-color;
		&.disabled{
			opacity: 0.65;
		}
	}
}


