.page-content{
	padding: 0 @pagePadding;
}
.purple{
	color: @font-color-purple;
}

// 复选框
.check-box {	
	font-size: 24px;
	margin-top: 32px;
	line-height: 36px;
	.iconfont{
		margin-left: 8px;
		margin-right: 12px;
		float: left;
		+ span{
			color: #000;
		}
	}
	.icon-checked{
		color: @font-color-purple;
	}
}

.content-white{
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.page{
		background: #fff;
		height: 100%;
	}
	.link-service{
		background: #fff;
	}
}
@keyframes rotateRound {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.loading-icon{
	display: inline-block;
	margin-right: 10px;
	width: 32px;
	height: 32px;
	vertical-align: middle;
	background: url('@{imgUrl}icon-loading.png') no-repeat ;
	background-size: 32px 32px;
	animation: rotateRound 1.5s linear infinite ;
}