@modal-width:640px;
@modal-bg-color:#fff;
@border-color:#e2e2e2;
@border-radius:26px;

.modal{
    width: @modal-width;
    min-height: 356px;
    display: none;
    margin: 0px auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius:@border-radius;
    background-color: @modal-bg-color;
    z-index: 9;
    &-head{
        padding-top: 64px;
        height: 48px;
        position: relative;
        .title{
            text-align: center;
            font-size: 32px;
            display: flex;
            align-items: center;
            width: 100%;
            font-weight: bold;
            height: 100%;
            flex-shrink: 0;
            justify-content: center;
            color: @font-color-dark;
        }
        // .icon-close{
        //     position: absolute;
        //     top: 33px;
        //     right: 33px;
        // }
    }
    &-body{
        padding: 30px 48px;
        text
        p{
            line-height: 42px;
            font-size: 28px;
        }
        .result-icon{
            text-align: center;
        }

        .iconfont{
            width: 68px;
            margin: 38px auto 32px;
        }
        .icon-close{
            font-size: 68px;
            color:#FF5050;
        }
        .result-warnning{
            margin: 16px auto;
            color: @font-color-yellow;
        }
        .result-desc{
            margin-bottom:12px;
            width: 100%;
            height: 48px;
            font-size: 32px;
            color: rgba(0, 0, 0, 0.85);
            text-align: center;
            line-height: 48px;
        }
        .link-number{
            margin-top: 16px;
			display: flex;
			align-items: baseline;
			color:#697DF4;
			font-size: 32px;
			// text-align: center;
            justify-content: center;
            .iconfont{
                margin:0;
                width: 48px;
            }
		}
        .result-tip{
            width: 100%;
            height: 68px;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #9A9EA7;
            text-align: left;
            &.tip-flex{
              font-size: 28px;
              text-align: left;
              span{
                margin-bottom:6px;
                width: max-content;
                word-break: break-all;
              }
              .text{
                color:#8c8c8c;
                word-break: break-all;
              }
              .number{
                color:#697df4;
                padding:0 2px;
              }
            }
          }
          .modal-btn-group{
              display: flex;
              margin-top: 48px;
              align-content: space-around;
              justify-content: space-evenly;
              .btn{
                  width: 248px;
                  height: 80px;
                  line-height: 80px;
                  + .btn{
                      margin-left: 48px;
                  }
              }
          }
          
    }
    &-footer{
        height: 70px;
        border-top: 1px solid @border-color;
    }

    &.modal-tip{
        .modal-body{
            padding: 50px 30px 30px;
            .error-tip{
                color: @font-color-red;
            }
            .scan-code{
                text-align: center;
                line-height: 28px;
                .weak-tip{
                    color: @font-color;
                    margin: 0.5em 0 27px;
                }
                >.weak-tip{
                    margin-top: 36px;
                }
                .code{
                    width: 250px;
                    height: 250px;
                    vertical-align: middle;
                    margin: 34px 0 ;
                }
                .tip{
                    color: @font-color;
                    margin-top: 0.5em;
                }
            }
        }
        .modal-footer{
            border-top: 1px solid @border-color; //ignore
            height: 88px;
            line-height: 88px;
            text-align: center;
            font-size: 30px;
        }
    }
    // 展示信息
    &.modal-txt{
        .modal-body{
            padding: 0 48px;
        }
        .info{
            color: #8C8C8C;
            line-height: 40px;
            margin: 12px auto 64px;
        }
    }
}
.modal-backdrop{
    position: fixed;
    display: none;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 8;
}