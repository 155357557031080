.page-index {
  min-height: 100%;
  .loading {
    margin-top: 735px;
    text-align: center;
    height: 32px;
    line-height: 32px;
  }
  .header {
    height: 272px;
    background: linear-gradient(301deg, #8a8eff 0%, #526aff 100%);
    .header-wrap {
      height: 100%;
      padding: 0 48px;
      box-sizing: border-box;
      background: url("@{imgUrl}bg-circle.png") no-repeat -20px 12px;
      background-size: 248px 248px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .user-icon {
      img {
        display: block;
        margin: 68px auto;
        width: 112px;
        height: 112px;
        border-radius: 50%;
      }
    }
    .user-info {
      margin-left: 32px;
      color: @font-color-white;
      max-width: 500px;
      .user-name {
        height: 40px;
        font-size: 28px;
        font-weight: 400;
        line-height: 40px;
        .txtEllipsis();
      }
      .user-account {
        margin-top: 4px;
        font-size: 32px;
        height: 48px;
        line-height: 48px;
        .txtEllipsis();
      }
    }
  }
  .account-info{
    .list-item{
      height: 160px;
      + .list-item {
        border-top: 1px solid @split-line-color; //ignore
      }
      .item-intro{
        .item-desc{
          margin-top: 4px;
          padding-left: 0;
        }
      }
    }
  }
}

// 首页&账户安全中心列表
.account-info {
  margin-top: 32px;
  margin-bottom: 80px;
  .list-item {
    display: flex;
    height: 144px;
    align-items: center;
    color: inherit;
    .item-ico {
      width: 80px;
      height: 80px;
      + .item-intro{
        margin-left: 20px;
      }

      &.ico-phone {
        background: url("@{imgUrl}phone.png") no-repeat;
        background-size: 80px 80px;
      }
      &.ico-lock {
        background: url("@{imgUrl}lock.png") no-repeat;
        background-size: 80px 80px;
      }
      &.ico-ID {
        background: url("@{imgUrl}ID.png") no-repeat;
        background-size: 80px 80px;
      }
      &.ico-security {
        background: url("@{imgUrl}security.png") no-repeat;
        background-size: 80px 80px;
      }
    }
    .item-intro {
      flex: 1;
      .item-name {
        font-size: 30px;
        color: @font-color-dark;
        height: 44px;
        line-height: 44px;
        .iconfont{
          font-size: 40px;
          margin-right: 16px;
          &.icon-security{
            color: #69ABF4;
          }
          &.icon-umbrella{
            color: #00DDD7;
          }
          &.icon-pwd-question{
            color: #46E690;
          }
          &.icon-logoff{
            color: #697DF4;
          }
        }
      }
      .item-desc {
        margin-top: 8px;
        font-size: 24px;
        color: @font-color;
        padding-left: 56px;
      }
    }

    .item-right {
      font-size: 28px;
      line-height: 44px;
      color: @font-color;
      .tip {
        color: @font-color-purple;
      }
      .icon-success {
        color: @font-color-green;
        + .tip {
          color: @font-color-green;
        }
      }
      .icon-arrow-right {
        margin-left: 16px;
        font-size: 24px;
      }
    }
  }
}

.tips {
  padding: 0 @pagePadding 100px;
  color: @font-color;
  h3 {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 28px;
  }
  p {
    font-size: 24px;
    line-height: 1.5;
    a {
      color: @font-color-purple;
    }
  }
}
