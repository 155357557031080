.page-detail{
	.record-brief{
		padding: 42px 0 102px;

		text-align: center;
		img{
			display: inline-block;
			width: 162px;
			margin-bottom: 42px;

		}	
		h3{
			font-size: 32px;
			color: @font-color;
			line-height: 32px;

		}
		h2{
			font-size: 50px;
			font-weight: 500;
			line-height: 70px;
			color: @font-color-dark;
			margin: 18px 0;
		}
		.result{
			font-size: 28px;
			color: @font-color;
			line-height: 28px;
		}
	}
	.record-detail{
		border-top: 1px solid @border-color; //ignore
		padding-top: 50px;
		line-height: 62px;
		font-size: 24px;
		margin: 0 @pagePadding;
		.list-item{
			display: flex;
			color: @font-color;
			.label{
				width: 162px;
				color: @font-color;
			}
		}
	}
}