.account-cancellation{
	// width: 100%;
	height: 100%;
	.title {
		width: 100%;
		margin-bottom: 22px;
		border-bottom: 2px solid #dedede;
		text-align: center;
		font-size: 32px;
		color:#262626;
		height: 96px;
		line-height: 96px;
		position: relative;
		.iconfont{
			position: absolute;
			color:#979797;
			left: 32px;
		}
	}
	.content-body{
		//title 120px + 
		min-height: calc(100% - 370px);
		height: auto;
		padding:0px @pagePadding ;
		.explain-clause-tip{
			font-size: 32px;
			font-weight: 500;
			color:#262626;
			line-height: 48px;
			padding:16px 0;
		}
		.explain-warining{
			font-size: 28px;
			color:#f8c232;
			padding-bottom: 16px;
		}
		.explain-clause-box{
			padding-top: 32px;
			font-size: 28px;
			color:rgba(0,0,0,.85);
			line-height: 44px;
			.explain-clause {
				margin-bottom: 40px;
			}
		}
		
	}
	.footer-agreement{
		padding:0px 32px 48px 32px;
		.check-box{
			margin: 0 0 32px 8px ;	
		}
	
		// .content-agreement{
		// 	display: flex;
		// 	flex-direction: row ;
		// 	padding-bottom: 36px;
		// 	padding-left: 18px;
		// 	align-items: center;
		// 	.input-checkbox{
		// 		width: 28px;
		// 		margin-right: 10px;
		// 		height: 28px;
		// 		margin-top: -2px;
		// 		background:url("@{imgUrl}checkbox.png") no-repeat;
		// 		&.selected{
		// 			background:url("@{imgUrl}checkbox-selected.png") no-repeat;
		// 		}
		// 	}
		// 	.agreement-text {
		// 		font-size: 24px;
		// 		color:#000;
				
		// 	}
		// 	.agreement-name{
		// 		font-size: 24px;
		// 		color:#697DF4;
		// 	}
		// }
	}
}