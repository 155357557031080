.form-input{
    // width: 100%;
	// // width: 686px;
    height: 96px;
    // background: #F6F6F6;
    // border-radius: 8px;

    &.icon{
        // padding-left: 88px;
        // position: absolute;
        // width: 36px;
        // height: 36px;
        // padding:30px 24px;
    }
}
.form-input-icon{

  
    // position:absolute;
    // top: 50%;
    // transform: translateY(-50%);
    
}