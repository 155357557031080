button, input{
	margin: 0;
	padding: 0;
	background: none;
	border: 0;
	outline: 0;
}

body{
	display: flex;
	flex-direction: column;
}
.main-content {
	flex: 1;
	width: 100%;
	padding: 0 @pagePadding;
	box-sizing: border-box;
	margin-top: 20px;
	.section-content{
		margin-bottom: 20px;
		padding: 30px 24px;
		background: rgba(255, 255, 255, 1);

		.section-title{
			width: 100%;
			& > p{
				height: 56px;
				font-size: 36px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #303030;
				line-height: 56px;
			}
		}
	}
	
	.payee-account{
		padding-top: 50px;
		.payee-bank-logo-box{
			position: relative;
			margin-right: 22px;
			width: 68px;
			height: 68px;
			overflow: hidden;
			border-radius: 100%;

			img{
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				display: block;
				width: 100%;
				border-radius: 100%;
			}
		}
		.payee-bank-info-box{
			& > p, & > span{
				display: block;
				width: 100%;
				
				font-family: PingFang SC;
				
			}
			& > p{
				margin-bottom: 8px;
				font-size: 28px;
				font-weight: bold;
				color: #303030;
				line-height: 30px;
			}
			& > span {
				font-size: 24px;
				font-weight: 400;
				color: #4F525A;
				line-height: 26px;
			}
		}
	}

	.withdraw-info{
		padding-bottom: 0;
		.withdraw-detail{
			padding: 42px 0 12px;
		}
		.withdraw-label{
			margin-right: 12px;
			height: 78px;
			font-size: 60px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #263156;
			line-height: 78px;
		}
		.withdraw-amount{
			position: relative;
			width: calc(100% - 214px);

			input{
				position: relative;
				z-index: 6;
				display: block;
				width: 100%;
				height: 78px;
				font-size: 76px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #263156;
				line-height: 78px;
				&::-webkit-input-placeholder {
					font-size: 24px;
					color: rgba(154, 158, 167, 1);
					transform: translate(0, -16px);
				}
				&::-moz-placeholder {
					font-size: 24px;
					color: rgba(154, 158, 167, 1);
					transform: translate(0, -16px);
				}
				&:-moz-placeholder {
					font-size: 24px;
					color: rgba(154, 158, 167, 1);
					transform: translate(0, -16px);
				}
				&:-ms-input-placeholder {
					font-size: 24px;
					color: rgba(154, 158, 167, 1);
					transform: translate(0, -16px);
				}
			}

			i{
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				z-index: 9;
				margin: auto;
				display: none;
				width: 36px;
				height: 36px;
				font-size: 28px;
				color: rgba(191, 193, 198, 1);
				text-align: center;
				line-height: 36px;
				&.active{
					display: block;
				}
			}
		}
		.withdraw-all-btn{
			margin-top: 24px;
			height: 32px;
			font-size: 28px;
			font-family: PingFang SC;
			font-weight: 400;
			// color: #697DF4;
			line-height: 32px;
		}

		.error-tip {
			height: 36px;
			padding-bottom: 26px;
			&.active{
				span{
					display: block;
				}
			}
			span{
				display: none;
				width: 100%;
				height: 36px;
				padding-left: 4px;
				box-sizing: border-box;
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #D22B2B;
				text-align: left;
				line-height: 36px;
			}
		}
	}

	.turn-out-info{
		.section-title{
			span{
				& > a{
					font-size: 24px;
					line-height: 36px;
				}
			}
		}
		.turn-out-methods{
			padding-top: 50px;
			width: 100%;
			li{
				width: 100%;
			}
		}
		.turn-out-method{
			padding-bottom: 46px;
			.icon-radiobox{
				margin-right: 18px;
				font-size: 30px;
				color: rgba(154, 158, 167, 1);
			}
			&.active{
				.icon-radiobox{
					color: rgba(105, 125, 244, 1);
				}	
			}
			.turn-out-method-tip{
				& > p{
					font-size: 28px;
					font-weight: bold;
					color: #303030;
					line-height: 36px;
				}
				& > span{
					display: block;
					font-size: 24px;
					color: #9A9EA7;
					line-height: 36px;
				}
			}
		}
	}

	.option-box{
		margin-top: 40px;
		margin-bottom: 40px;
	}
	
	.option-box + .link-service{
		position: static;
		display: inline-block;
	}
	.btn-group-vertical .btn{
		margin-bottom: 16px;
	  }	
}

.modal-security{
	font-size: 28px;
	&.modal-security-dynamic{
		transform: translate(-50%, -66%);
	}
	.modal-head{
		font-size: 30px;
		color: rgba(48, 48, 48, 1);
		border-bottom-color: rgba(226, 226, 226, 0.5);
		.icon-close{
			font-size: 24px;
			color: rgba(79, 82, 90, 1);
		}
	}
	.modal-body{
		padding: 60px 30px 90px;
		.input-label{
			span, a{
				height: 30px;
				line-height: 30px;
			}
			&.input-smscode{
				padding: 30px 0 0;
			}
		}
		.lk-forgot{
			&:hover{
				cursor: pointer;
				text-decoration: underline;
			}
		}

		.input-tip{
			span, button{
				height: 58px;
				line-height:58px;
			}
			span{
				color: rgba(154, 158, 167, 1);
			}
		}

		.input-item{
			margin: 30px 0 10px;
			width: 100%;
			height: 88px;
			box-sizing: border-box;
			border: 1px solid #E2E2E2;
			border-radius: 8px;
			overflow: hidden;
			input{
				display: block;
				width: 100%;
				padding: 22px;
				box-sizing: border-box;
				color: rgba(48, 48, 48, 1);
				line-height: 44px;
			}
			&.error{
				border-color: rgba(210, 43, 43, 1);
			}
			&.input-code{
				position: relative;
				margin-bottom: 30px;
				input{
					left: 0;
					top: 0;
					position: absolute;
					z-index: 9;
					padding-left: 42px;
					background: rgba(155, 0, 0, 0.3);
					opacity: 0;
				}
				span{
					position: relative;
					z-index: 6;
					display: flex;
					flex-direction: row;
					width: 100%;
					height: 100%;
					overflow: hidden;
					box-sizing: border-box;
					
					b{
						position: relative;
						flex: 1;
						font-size: 28px;
						font-weight: normal;
						color: rgba(48, 48, 48, 1);
						line-height: 88px;
						text-align: center;
						&::before, &::after{
							position: absolute;
							top: 0;
							bottom: 0;
							margin: auto;
							z-index: 9;
							content: '';
							width: 1px;
						}
						&::after{
							display: block;
							right: -1px;
							height: 100%;
							background: rgba(226, 226, 226, 1);
						}
						&:last-child{
							&::after{
								display: none;
							}
						}

						&.active{
							position: relative;
							&::before{
								left: 0;
								right: 0;
								display: block;
								width: 1px;
								height: 40px;
								background: rgba(52, 131, 229, 1);
								opacity: 1;
								animation: flashing 1s linear infinite forwards;
							}
							&.full{
								&::before{
									left: calc(50% + 14px);
									right: unset;
								}
							}
						}
					}
				}
			}
		}

		.error-tip{
			display: none;
			padding-bottom: 10px;
			&.active{
				display: block;
				span{
					display: block;
					width: 100%;
					font-size: 24px;
					color: #D22B2B;
					line-height: 30px;
				}
			}
		}

		.btns{
			padding-top: 30px;
			border: none;
		}
	}
}
.modal-security-error{
	.modal-body{
		padding: 0;
	}
	.modal-content{
		padding: 100px 30px 100px;
		box-sizing: border-box;
		p{
			display: block;
			width: 100%;
			font-size: 30px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #303030;
			text-align: center;
			line-height: 38px;
		}
	}
	.btns{
		position: relative;
		overflow: hidden;
		height: 88px;
		&::before, &::after{
			position: absolute;
			content: '';
			display: block;
			background: rgba(226, 226, 226, 1);
		}
		&::before{
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
		}
		&::after{
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 1px;
			height: 100%;
		}

		button{
			width: 50%;
			height: 100%;
			font-size: 30px;
			font-family: PingFang SC;
			font-weight: 500;
			color: @font-color;
			line-height: 88px;
		}
	}
}

@keyframes flashing {
	0% {
		opacity: 1;
	}
	49% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}